


































import {Component, Prop, Vue} from "vue-property-decorator";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import PortalInput from "@/components/common/PortalInput.vue";
import {CreateIRSLetterPayload} from "@/components/documents/notice/IRSLetter";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import Application from "@/state/Application";
import DocumentService from "@/services/DocumentService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Notifications from "@/state/Notifications";

@Component({
	components: {PortalCheckbox, SingleFileHolder, PortalInput}
})
export default class NoticeLetterCreationModal extends Vue {

	private req: CreateIRSLetterPayload = new CreateIRSLetterPayload();

	@Prop()
	private onSuccess!: () => void;

	private workspace: Workspace = Workspaces.getCurrent;

	public created(): void {
		this.req.companyId = this.workspace.id;
	}

	public create(): void {
		if (this.req.letterFileMeta == null) {
			Notifications.error("Upload the document!");
		} else {
			ifValid(this, () => {
				Application.startLoading()
				DocumentService.createIRSLetter(this.req).then(
					() => {
						this.$modal.hideAll()
						Notifications.success("Document uploaded!")
						Application.stopLoading()
						this.onSuccess();
					},
					(err) => processError(err, this)
				)
			})
		}
	}

	public setFalseNotifyIfNeed(): void {
		if (this.req.isHidden) {
			this.req.isNotify = false;
		}
	}
}

