






































































import {Component, Vue} from "vue-property-decorator";
import {
	AdminIRSLetterFilter,
	IRSLetter,
	UpdateIRSLetterPayload
} from "@/components/documents/notice/IRSLetter";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import Application from "@/state/Application";
import DocumentService from "@/services/DocumentService";
import {processError} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import NoticeLetterCreationModal from "@/components/documents/notice/NoticeLetterCreationModal.vue";
import Notifications from "@/state/Notifications";
import PortalInput from "@/components/common/PortalInput.vue";

@Component({
	components: {PortalInput, PaginationComponent, SingleFileHolder, PortalCheckbox, BTPortalCollapse}
})
export default class AdminNoticeLetterList extends Vue {
	private letters: Array<IRSLetter> = [];

	private workspace: Workspace = Workspaces.getCurrent;

	private totalPages = 1;

	private filter: AdminIRSLetterFilter = new AdminIRSLetterFilter();

	public created() {
		this.filter.companyId = this.workspace.id;
		this.loadIrsLetters()
	}

	private loadIrsLetters() {
		Application.startLoading()
		DocumentService.getIRSLettersByFilterForAdmin(this.filter).then(
			res => {
				if (res.data.data) {
					this.letters = res.data.data
					this.totalPages = res.data.countOfPages
				}
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private update(updateReq: UpdateIRSLetterPayload) {
		Application.startLoading()
		DocumentService.updateIRSLetter(updateReq).then(
			() => {
				Application.stopLoading()
				Notifications.success("The data has been successfully updated!")
				this.loadIrsLetters()
			},
			err => processError(err, this)
		)
	}

	private create() {
		this.$modal.show(
			NoticeLetterCreationModal,
			{
				onSuccess: () => this.loadIrsLetters()
			}
		)
	}

	private deleteLetter(letter: IRSLetter) {
		Application.startLoading();
		DocumentService.deleteIRSLetter(letter.id).then(
			() => {
				Application.stopLoading();
				this.loadIrsLetters()
			},
			(err) => processError(err, this)
		)
	}

	changePage(page: number){
		this.filter.page = page - 1;
		this.loadIrsLetters();
	}

	private reset() {
		this.filter = new AdminIRSLetterFilter();
		this.filter.companyId = this.workspace.id;
		this.loadIrsLetters()
	}
}

